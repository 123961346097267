import { io } from 'socket.io-client';

export const socket = io(process.env.REACT_APP_URL_WS, {
  path: '/ws',
  reconnectionDelayMax: 10000,
  transports: ['websocket'],
  autoConnect: false,
  reconnection: true, // Reconectar automáticamente
  reconnectionAttempts: 5, // Número máximo de intentos
  reconnectionDelay: 1000, // Retraso entre intentos
});
