import CustomLocalStorage from './localStorage';

export const isAuthorized = async (state, action) => {
  try {
    let path = state.location.pathname;
    if (!path.startsWith('/')) path = `/${path}`;
    const user = CustomLocalStorage.getItem('SICOB_APP_USER');
    if (!user) return false;
    const modules = user ? user.modulesAssing : [];
    if (modules) {
      return (
        modules.filter((module) => {
          const dataSubModules = module.subModules && module.subModules.length > 0 ? module.subModules : module.subModulesID;
          return (
            dataSubModules.filter((subModule) => {
              if ((subModule._id.path === path.replaceAll('/', '') || subModule.path === path.replaceAll('/', '')) && !action) {
                return true;
              } else if (path.match(`/${subModule._id.path}/`)) {
                if (subModule.processes.find((x) => x === action) != null) return true;
              }
              return false;
            }).length > 0
          );
        }).length > 0
      );
    }
  } catch (e) {
    console.log(e);
  }
  return false;
};

export const hasPermission = async (permission, path) => {
  if (!path) path = window.location.pathname;
  if (!path.startsWith('/')) path = `/${path}`;
  let founding = false;
  const user = CustomLocalStorage.getItem('SICOB_APP_USER');
  if (!user) return false;
  const modules = user ? user.modulesAssing : [];
  if (modules) {
    for (let i = 0; i < modules.length; i++) {
      const module = modules[i];
      const dataSubModules = (module.subModules && module.subModules.length > 0 ? module.subModules : module.subModulesID).filter((x) => x && x._id);
      for (let j = 0; j < dataSubModules.length; j++) {
        const subModule = dataSubModules[j];
        if (
          subModule._id.path === path.replaceAll('/', '') ||
          subModule.path === path.replaceAll('/', '') ||
          path.match(`/${subModule._id.path}/`) ||
          path.match(`/${subModule.path}/`)
        ) {
          if (subModule.processes.find((x) => x === permission) != null) return true;
        }
      }
    }
  }
  return founding;
};

export const hasAccess = async (path) => {
  if (!path) path = window.location.pathname;
  if (!path.startsWith('/')) path = `/${path}`;
  let founding = false;
  const user = CustomLocalStorage.getItem('SICOB_APP_USER');
  if (!user) return false;
  const modules = user ? user.modulesAssing : [];
  if (modules) {
    for (let i = 0; i < modules.length; i++) {
      const module = modules[i];
      const dataSubModules = (module.subModules && module.subModules.length > 0 ? module.subModules : module.subModulesID).filter((x) => x && x._id);
      for (let j = 0; j < dataSubModules.length; j++) {
        const subModule = dataSubModules[j];
        if (
          subModule._id.path === path.replaceAll('/', '') ||
          subModule.path === path.replaceAll('/', '') ||
          path.match(`/${subModule._id.path}/`) ||
          path.match(`/${subModule.path}/`)
        ) {
          return true;
        }
      }
    }
  }
  return founding;
};

String.prototype.replaceAll = function (search, replacement) {
  var target = this;
  return target.replace(new RegExp(search, 'g'), replacement);
};
