import { create } from 'zustand';
import CustomLocalStorage from '@utils/localStorage.js';

const SICOB_APP_TOKEN = 'SICOB_APP_TOKEN';
const SICOB_APP_USER = 'SICOB_APP_USER';
const SICOB_APP_AGENTE = 'SICOB_APP_AGENTE';
const SICOB_APP_TASKS = 'SICOB_APP_TASKS';
const SICOB_APP_MESSAGES = 'SICOB_APP_MESSAGES';
const SICOB_APP_NOTIFICATIONS = 'SICOB_APP_NOTIFICATIONS';

export const useAuthStore = create((set, get) => ({
  user: CustomLocalStorage.getItem(SICOB_APP_USER),
  token: CustomLocalStorage.getItem(SICOB_APP_TOKEN),
  agente: CustomLocalStorage.getItem(SICOB_APP_AGENTE),
  tasks: CustomLocalStorage.getItem(SICOB_APP_TASKS, []),
  notifications: CustomLocalStorage.getItem(SICOB_APP_NOTIFICATIONS, []),
  messages: CustomLocalStorage.getItem(SICOB_APP_MESSAGES, []),
  geolocation: { lat: 0, lng: 0 },
  loading: false,
  hasPermisionPosition: false,
  setUser: (user) => {
    set({ user });
    CustomLocalStorage.setItem(SICOB_APP_USER, user);
  },
  setToken: (token) => {
    set({ token });
    CustomLocalStorage.setItem(SICOB_APP_TOKEN, token);
  },
  setAgente: (agente) => {
    set({ agente });
    CustomLocalStorage.setItem(SICOB_APP_AGENTE, agente);
  },
  setTasks: (tasks) => {
    set({ tasks });
    CustomLocalStorage.setItem(SICOB_APP_TASKS, tasks);
  },
  setMessages: (messages) => {
    set({ messages });
    CustomLocalStorage.setItem(SICOB_APP_MESSAGES, messages);
  },
  setNotifications: (notifications) => {
    set({ notifications });
    CustomLocalStorage.setItem(SICOB_APP_NOTIFICATIONS, notifications);
  },
  setLoading: (loading) => set({ loading }),
  setGeolocation: (geolocation) => set({ geolocation }),
  isLogged: () => get().token !== null && get().user !== null,
}));
