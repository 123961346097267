import Menu from '@components/application/home/Menu';
import NavBar from '@components/application/home/NavBar';
import ErrorBoundary from '@components/ErrorBoundary';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import cache from '@utils/cache';
import { useStoreSidebar } from '@stores/catalogs.store';
import useAuth from '@hooks/useAuth';

const App = (props) => {
  const getStoreSidebar = useStoreSidebar();
  const { token } = useAuth();
  useEffect(() => {
    cache();
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);

  useEffect(() => {
    collapsedMenu();
  }, [getStoreSidebar.collapsed]);

  const updateWindowDimensions = () => {
    if (window.innerWidth > 990) {
      if (!getStoreSidebar.collapsed) {
        document.body.classList.remove('sidebar-collapse');
      } else {
        document.body.classList.remove('sidebar-closed');
        document.body.classList.remove('sidebar-open');
      }
    } else {
      if (getStoreSidebar.sidebar) {
        document.body.classList.add('sidebar-collapse');
      }
      document.body.classList.add('sidebar-closed');
      document.body.classList.remove('sidebar-open');
    }
  };

  const collapsedMenu = () => {
    if (document.body.classList.contains('sidebar-collapse')) {
      document.body.classList.remove('sidebar-collapse');
    } else {
      document.body.classList.add('sidebar-collapse');
    }
    if (document.body.classList.contains('sidebar-closed')) {
      document.body.classList.remove('sidebar-closed');
      document.body.classList.add('sidebar-open');
    } else if (document.body.classList.contains('sidebar-open')) {
      document.body.classList.remove('sidebar-open');
      document.body.classList.add('sidebar-closed');
    }
  };

  return (
    <ErrorBoundary {...props}>
      <div className='main-header'>
        <NavBar {...props} />
      </div>
      <Menu {...props} />
      <Outlet />
      <footer className='main-footer' style={!token ? { marginLeft: '0px' } : {}}>
        <div className='pull-right hidden-xs'>
          <b>Version</b> 3.0.0
        </div>
        <strong>
          Copyright © 2024{' '}
          <a href='https://codigoblanco.mx' className='link-footer'>
            Código Blanco
          </a>
          .
        </strong>{' '}
        Todos los derechos reservados.
      </footer>
    </ErrorBoundary>
  );
};

App.propTypes = {};

export default App;
