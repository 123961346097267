import { socket } from './socket';

const subscribeToEvent = (eventName, callback) => {
  socket.on(eventName, callback);
};

const unsubscribeFromEvent = (eventName, callback) => {
  socket.off(eventName, callback);
};

const emitEvent = (eventName, data) => {
  socket.emit(eventName, data);
};

const connectSocket = () => {
  if (!socket.connected) socket.connect();
};

const disconnectSocket = () => {
  if (socket.connected) socket.disconnect();
};

export const socketManager = {
  connect: connectSocket,
  disconnect: disconnectSocket,
  subscribe: subscribeToEvent,
  unsubscribe: unsubscribeFromEvent,
  emit: emitEvent,
};
