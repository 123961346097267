import React from 'react';
import { Routes } from 'react-router-dom';
import { RoutesApp } from './app/routes';
import { BrowserRouter } from 'react-router-dom';
import NotificationComponent from '@components/NotificationsComponent';
import { initializeNotifications } from '@stores/catalogs.store';

const App = () => {
  initializeNotifications();
  return (
    <>
      <BrowserRouter>
        <Routes>{RoutesApp}</Routes>
      </BrowserRouter>
      <NotificationComponent />
    </>
  );
};

export default App;
