import PropTypes from 'prop-types';
import { hasAccess } from '@utils/permissions';

const IfAccess = ({ path, children }) => {
  if (hasAccess(path) && children) return children;
  return null;
};

IfAccess.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string,
};

export default IfAccess;
