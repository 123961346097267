import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuth from '@hooks/useAuth';

export default function PrivateRoute() {
  const { authenticated } = useAuth();
  if (!authenticated) {
    console.log('PrivateRoute.js: User is not authenticated. Redirecting to login page');
    return <Navigate to={'/login'}></Navigate>;
  }
  return (
    <div className='content-wrapper'>
      <Outlet />
    </div>
  );
}
