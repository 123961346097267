import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useAuth from '@hooks/useAuth';

export default function PublicRoute() {
  const { authenticated } = useAuth();
  let location = useLocation();
  if (authenticated && location.pathname === '/login') {
    console.log('PublicRoute.js: User is authenticated and trying to access login page. Redirecting to dashboard');
    return <Navigate to={'/dashboard'}></Navigate>;
  }
  return <Outlet />;
}
