import React, { useEffect, useRef } from 'react';
import NotificationSystem from 'react-notification-system';
import Swal from 'sweetalert2';
import { useStoreNotification } from '@stores/catalogs.store.js';

const NotificationComponent = () => {
  const { notificaciones } = useStoreNotification();
  const { alertas } = useStoreNotification();
  const notificationSystem = useRef(null);
  useEffect(() => {
    if (alertas.length > 0) {
      const latestAlert = alertas[alertas.length - 1];
      Swal.fire({
        title: latestAlert.message,
        icon: latestAlert.type,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }, [alertas]);
  useEffect(() => {
    if (notificaciones.length > 0) {
      const latestNotification = notificaciones[notificaciones.length - 1];
      notificationSystem.current.addNotification({
        level: latestNotification.type,
        position: 'br',
        ...latestNotification,
        children: latestNotification.link && (
          <div>
            <a href={'/' + latestNotification.link} className='btn btn-outline-warning btn-sm'>
              Ver más...
            </a>
          </div>
        ),
      });
    }
  }, [notificaciones]);
  return <NotificationSystem ref={notificationSystem} />;
};

export default NotificationComponent;
