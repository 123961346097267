import { useState, useEffect, useRef } from 'react';
import { postRequest, getRequest } from '@utils/api';
import { socketManager } from '../../socketManager';
import { useStoreNotification } from '@stores/catalogs.store';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '@stores/auth.store';
// import { searchAddress } from '../utils/googleMaps';

const useAuth = () => {
  const navigate = useNavigate();
  const geolocatizacion = useRef(null);
  const addNotification = useStoreNotification((state) => state.addNotification);
  const {
    user,
    agente,
    token,
    loading,
    setUser,
    setToken,
    setAgente,
    setTasks,
    setMessages,
    setNotifications,
    tasks,
    messages,
    notifications,
    isLogged,
  } = useAuthStore();
  const [collapsedNavbar, setCollapsedNavbar] = useState(user?.navbar_colapsed, false);

  useEffect(() => {
    if (!token) return;
    // if (!agente?.es_tecnico) return;
    let watchId;

    const startTracking = () => {
      watchId = navigator.geolocation.watchPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          geolocatizacion.current = { lat: latitude, lng: longitude };
          // const address = await searchAddress(latitude, longitude);
          // console.log('Geolocation:', { lat: latitude, lng: longitude, address });
          // setGeolocation({ lat: latitude, lng: longitude });
        },
        (error) => console.error('Error obteniendo la ubicación:', error),
        { enableHighAccuracy: true, maximumAge: 0 }
      );
    };

    startTracking();

    return () => {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId);
      }
    };
  }, [token, agente]);

  const login = async (credentials) => {
    try {
      const { user: userLogin, token, agente } = await postRequest({ url: `users/login`, body: credentials });
      if (!userLogin) return;
      setToken(token);
      setUser(userLogin);
      setAgente(agente);
      const { tasks, notifications, messages } = await getRequest({ url: `user/data/${userLogin.id}` });
      setTasks(tasks);
      setMessages(messages);
      setNotifications(notifications);
      notifications.forEach((notification) => {
        addNotification({ type: 'warning', message: notification.mensaje, title: notification.asunto, link: notification.url });
      });
      socketManager.emit('LOGIN', { fullname: userLogin.name, email: userLogin.email, userId: userLogin.id });
      setTimeout(() => navigate('/dashboard'), 2000);
    } catch (error) {
      console.error('Failed to login:', error);
    }
  };
  const logout = async () => {
    try {
      setAgente(null);
      setTasks(null);
      setNotifications(null);
      setMessages(null);
      setUser(null);
      setToken(null);
    } catch (error) {
      console.error('Failed to logout:', error);
    }
  };
  return {
    user,
    token,
    loading,
    login,
    logout,
    collapsedNavbar,
    agente,
    tasks,
    messages,
    notifications,
    setCollapsedNavbar,
    authenticated: isLogged(),
    geolocation: geolocatizacion?.current,
  };
};

export default useAuth;
