import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import PropTypes from 'prop-types';
import useAuth from '@hooks/useAuth';

const LinkMenu = ({ module, eventKey }) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => console.log('clicked', eventKey));
  return (
    <a href='#' onClick={decoratedOnClick}>
      <i className={`glyphicon glyphicon-${module.icon}`}></i>
      <span>{module.module.toUpperCase()}</span>
    </a>
  );
};

LinkMenu.propTypes = {
  module: PropTypes.object,
  eventKey: PropTypes.number,
};

const activeTab = (location, path) => {
  if (location.pathname === path) {
    return { fontWeight: '600', backgroundColor: '#e5e7eb', display: 'block' };
  }
  return { display: 'block' };
};

export default function Menu() {
  const { user, token } = useAuth();
  let location = useLocation();
  let modules = user ? user.modulesAssing : [];
  const moduloIndexActivo = modules.find((module, i) => {
    const moduleSubs = module?.subModulesID ? module.subModules : module.subModulesID;
    if (moduleSubs.length > 0 && moduleSubs.filter((subModule) => location.pathname.indexOf(subModule.path) >= 0)) {
      return i;
    }
    return 0;
  });

  const moduloActivo = (modulo) => {
    if (!modules) return false;
    return (
      modules.filter((module) => {
        const moduleName = module?.moduleID;
        const moduleSubs = module?.subModulesID;
        if (modulo === moduleName.module && moduleSubs.length > 0) {
          return (
            moduleSubs.filter((subModule) => {
              return location.pathname.indexOf(subModule.path) >= 0;
            }).length > 0
          );
        }
        return false;
      }).length > 0
    );
  };

  if (!token) {
    return null;
  }

  return (
    <aside className='main-sidebar'>
      <section className='sidebar'>
        <Accordion as='ul' defaultActiveKey={moduloIndexActivo} className='sidebar-menu tree' data-widget='tree'>
          <li className='header'>MÉNU PRINCIPAL</li>
          {modules.map((module, itemModule) => {
            const moduleName = module?.moduleID;
            const moduleSubs = module?.subModulesID;
            if (moduleSubs.length > 0) {
              const active = moduloActivo(moduleName.module);
              return (
                <li key={itemModule} className={`treeview ${active && 'active'}`}>
                  <LinkMenu module={moduleName} eventKey={itemModule} />
                  <Accordion.Collapse as='ul' eventKey={itemModule} className='treeview-menu'>
                    <>
                      {moduleSubs.map((subModule, itemSubModule) => (
                        <li key={itemModule + itemSubModule}>
                          <Link to={`/${subModule.path}`} style={activeTab(location, `/${subModule.path}`)}>
                            <i className={`glyphicon glyphicon-menu-right`}></i>
                            {subModule.name}
                          </Link>
                        </li>
                      ))}
                    </>
                  </Accordion.Collapse>
                </li>
              );
            }
          })}
        </Accordion>
      </section>
    </aside>
  );
}
